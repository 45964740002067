import axiosApiClient from '..';
import { handleError, throwErrorIfWrongStatus } from './utils';

const BASE_URL = '/a/influencer-facts';

class InfluencerFactService {
  fetchInfluencerFactRequests = async (page, search) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/requests?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total, perPage: perPageResponse } = response.data.data;
      return { data, maxReached: total <= +page * +perPageResponse, page };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencer fact requests.');
    }
  };

  fetchFactRequestsByInfluencer = async (page, search, influencerId) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/requests/${influencerId}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total, perPage: perPageResponse } = response.data.data;
      return { data, maxReached: total <= +page * +perPageResponse, page };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencer fact requests.');
    }
  };

  fetchInfluencerFacts = async (page, search, influencerId) => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${influencerId}?page=${page}&search=${search}`);
      throwErrorIfWrongStatus(response, 200);
      const { data, total, perPage: perPageResponse } = response.data.data;
      return { data, maxReached: total <= +page * +perPageResponse, page };
    } catch (err) {
      throw handleError(err, 'Error occurred while fetching influencer facts.');
    }
  };

  createInfluencerFacts = async (influencerId, facts) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}`, { influencerId, facts });
      throwErrorIfWrongStatus(response, 200);
      const { influencerFacts } = response.data.data;
      return { influencerFacts };
    } catch (err) {
      throw handleError(err, 'Error occurred while creating influencer facts.');
    }
  };

  acceptInfluencerFactRequest = async influencerFactRequestIds => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/requests/accept`, { influencerFactRequestIds });
      throwErrorIfWrongStatus(response, 200);
      return { influencerFactRequestIds };
    } catch (err) {
      throw handleError(err, 'Error occurred while accepting influencer fact request.');
    }
  };

  rejectInfluencerFactRequest = async influencerFactRequestId => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/requests/reject/${influencerFactRequestId}`);
      throwErrorIfWrongStatus(response, 200);
      return { influencerFactRequestId };
    } catch (err) {
      throw handleError(err, 'Error occurred while rejecting influencer fact request.');
    }
  };

  testInfluencerFactRequest = async influencerFactRequestId => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/requests/test/${influencerFactRequestId}`);
      throwErrorIfWrongStatus(response, 200);
      const data = response.data.data;
      return data;
    } catch (err) {
      throw handleError(err, 'Error occurred while rejecting influencer fact request.');
    }
  };

  deleteInfluencerFact = async influencerFactId => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${influencerFactId}`);
      throwErrorIfWrongStatus(response, 200);
      return { influencerFactId };
    } catch (err) {
      throw handleError(err, 'Error occurred while deleting influencer fact.');
    }
  };

  editInfluencerFact = async (influencerFactId, text) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/${influencerFactId}`, { text });
      throwErrorIfWrongStatus(response, 200);
      const { influencerFact } = response.data.data;
      return { influencerFact };
    } catch (err) {
      throw handleError(err, 'Error occurred while updating influencer fact.');
    }
  };
}

const influencerFactService = new InfluencerFactService();
export default influencerFactService;
