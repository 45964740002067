import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  selectSelectedUserInfluencerInfo,
  selectSelectedUserInfo,
  setSelectedUserInfluencer,
} from '../../../store/slices/user/slice';
import {
  ChatActivitiesContainer,
  ChatActivitiesList,
  ChatActivity,
  ChatHeader,
  ChatMessagesContainer,
  ChatSection,
  Container,
  DateDivider,
  FetchMoreSpinner,
  FetchMoreSpinnerContainer,
  InfiniteScrollBottomMarker,
  InfiniteScrollTopMarker,
  MainContent,
  Spinner,
  SpinnerContainer,
  TextContainer,
  Timestamp,
} from './UserInfluencerDetailsPage.styles';
import MainLoader from '../../../components/utils/loaders/MainLoader/MainLoader';
import { fetchInteractions, searchActivityInChat } from '../../../store/slices/interaction/asyncThunks';
import {
  resetInteractionsInfo,
  selectFetchInteractionsInfo,
  selectSearchActivityInChatPending,
} from '../../../store/slices/interaction/slice';
import { ChatBubble } from './ChatBubble/ChatBubble';
import { BundleDisplay } from './BundleDisplay/BundleDisplay';
import VideoDisplay from './VideoDisplay/VideoDisplay';
import PhotoDisplay from './PhotoDisplay/PhotoDisplay';
import CenteredBubble from './CenteredBubble/CenteredBubble';
import { CHAT_ACTIVITY_TARGET, CHAT_ACTIVITY_TYPE } from '../../../utils/constants';
import { Text14Regular, Text16Bold } from '../../../components/utils/texts/text.styles';
import SpinnerGIF from '../../../assets/images/spinner.gif';
import Spinner2GIF from '../../../assets/images/loader.gif';

export const SCROLL_DIRECTION = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
};

function UserInfluencerDetailsPage() {
  const INTERACTIONS_BATCH_SIZE = 10;
  const { userInfluencerId } = useParams();
  const dispatch = useDispatch();

  const { user: selectedUser } = useSelector(selectSelectedUserInfo);
  const { userInfluencer: selectedUserInfluencer } = useSelector(selectSelectedUserInfluencerInfo);
  const { data, pending, total, fetchMoreTopPending, fetchMoreBottomPending } =
    useSelector(selectFetchInteractionsInfo);
  const searchActivityInChatPending = useSelector(selectSearchActivityInChatPending);

  const [pageTop, setPageTop] = useState(1);
  const [pageBottom, setPageBottom] = useState(1);
  const [selectedChatActivity, setSelectedChatActivity] = useState(null);
  const loadMoreTopRef = useRef(null);
  const loadMoreBottomRef = useRef(null);
  const [shouldRerender, setShouldRerender] = useState(false);

  const chatContainerRef = useRef(null);

  useEffect(() => {
    const selectedUserInfluencer = selectedUser.userInfluencers.find(
      userInfluencer => userInfluencer.id === userInfluencerId,
    );
    dispatch(setSelectedUserInfluencer(selectedUserInfluencer || null));
    return () => {
      dispatch(setSelectedUserInfluencer(null));
    };
  }, [dispatch, selectedUser, userInfluencerId]);

  useEffect(() => {
    if (!chatContainerRef.current) return;
    if (!selectedUserInfluencer?.id) return;
    dispatch(fetchInteractions({ userInfluencerId: selectedUserInfluencer.id, page: 1 }))
      .unwrap()
      .then(() => {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      });
  }, [dispatch, selectedUserInfluencer?.id]);

  useEffect(() => {
    return () => {
      dispatch(resetInteractionsInfo());
    };
  }, [dispatch]);

  const handleTopIntersection = useCallback(
    entries => {
      const [entry] = entries;
      if (
        entry.isIntersecting &&
        !pending &&
        !fetchMoreBottomPending &&
        !fetchMoreTopPending &&
        total > pageTop * INTERACTIONS_BATCH_SIZE
      ) {
        const previousScrollHeight = chatContainerRef.current.scrollHeight;
        dispatch(
          fetchInteractions({
            userInfluencerId: selectedUserInfluencer.id,
            page: pageTop + 1,
            direction: SCROLL_DIRECTION.TOP,
          }),
        )
          .unwrap()
          .then(() => {
            setPageTop(prev => prev + 1);
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight - previousScrollHeight + 50;
          });
      }
    },
    [pending, total, pageTop, dispatch, selectedUserInfluencer, fetchMoreBottomPending, fetchMoreTopPending],
  );

  const handleBottomIntersection = useCallback(
    entries => {
      const [entry] = entries;
      if (entry.isIntersecting && !pending && !fetchMoreBottomPending && !fetchMoreTopPending && pageBottom > 1) {
        if (selectedChatActivity) {
          dispatch(
            fetchInteractions({
              userInfluencerId: selectedUserInfluencer.id,
              page: pageBottom - 1,
              direction: SCROLL_DIRECTION.BOTTOM,
            }),
          )
            .unwrap()
            .then(() => {
              setPageBottom(prev => prev - 1);
            });
        }
      }
    },
    [
      pending,
      pageBottom,
      selectedChatActivity,
      selectedUserInfluencer,
      dispatch,
      fetchMoreBottomPending,
      fetchMoreTopPending,
    ],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleTopIntersection, { threshold: 1.0 });
    const target = loadMoreTopRef.current;

    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [handleTopIntersection, shouldRerender]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleBottomIntersection, { threshold: 1.0 });
    const target = loadMoreBottomRef.current;

    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [handleBottomIntersection, shouldRerender]);

  if (!selectedUserInfluencer) {
    return (
      <Container>
        <MainLoader />
      </Container>
    );
  }
  return (
    <MainContent>
      <ChatSection>
        <ChatHeader>Chat with {selectedUserInfluencer.influencer.name}</ChatHeader>
        {searchActivityInChatPending || pending ? (
          <SpinnerContainer>
            <Spinner src={SpinnerGIF} />
          </SpinnerContainer>
        ) : (
          <ChatMessagesContainer ref={chatContainerRef}>
            <InfiniteScrollTopMarker ref={loadMoreTopRef} />
            {fetchMoreTopPending && (
              <FetchMoreSpinnerContainer>
                <FetchMoreSpinner src={Spinner2GIF} />
              </FetchMoreSpinnerContainer>
            )}
            {data.map((item, index) => {
              const currentDate = new Date(item.timestamp).toLocaleDateString();
              const previousDate = index > 0 ? new Date(data[index - 1].timestamp).toLocaleDateString() : null;
              const isNewDay = currentDate !== previousDate;
              return (
                <Fragment key={item.id}>
                  {isNewDay && <DateDivider>{currentDate}</DateDivider>}
                  {item.type === CHAT_ACTIVITY_TYPE.MESSAGE && item.message && (
                    <ChatBubble sender={item.sender} timestamp={item.timestamp} responseFormat={item.format} reasoning={item.reasoning || null}>
                      {item.message}
                    </ChatBubble>
                  )}
                  {item.type === CHAT_ACTIVITY_TYPE.OFFER && (
                    <>
                      {item.target === CHAT_ACTIVITY_TARGET.PREMIUM_PHOTO && (
                        <PhotoDisplay id={item.id} src={item.url} cost={item.metadata.cost} />
                      )}
                      {item.target === CHAT_ACTIVITY_TARGET.FREE_PHOTO && (
                        <PhotoDisplay id={item.id} src={item.url} cost={0} />
                      )}
                      {item.target === CHAT_ACTIVITY_TARGET.PREMIUM_VIDEO && (
                        <VideoDisplay id={item.id} src={item.url} cost={item.metadata.cost} />
                      )}
                      {item.target === CHAT_ACTIVITY_TARGET.FREE_VIDEO && (
                        <VideoDisplay id={item.id} src={item.url} cost={0} />
                      )}
                      {item.target === CHAT_ACTIVITY_TARGET.BUNDLE && (
                        <BundleDisplay id={item.id} media={item.media} cost={item.metadata.cost} />
                      )}
                      {item.target === CHAT_ACTIVITY_TARGET.DICK_RATE && (
                        <CenteredBubble
                          id={item.id}
                          type={item.type}
                          cost={item.metadata.cost}
                          target={item.target}
                          metadata={item.metadata}
                        />
                      )}
                      {item.target === CHAT_ACTIVITY_TARGET.SUBSCRIPTION && (
                        <CenteredBubble id={item.id} type={item.type} cost={item.metadata.cost} target={item.target} />
                      )}
                    </>
                  )}
                  {item.type === CHAT_ACTIVITY_TYPE.PURCHASE && (
                    <CenteredBubble
                      id={item.id}
                      type={item.type}
                      cost={item.metadata.cost}
                      url={item.url}
                      target={item.target}
                    />
                  )}
                  {item.type === CHAT_ACTIVITY_TYPE.EVENT && (
                    <>
                      {item.target === CHAT_ACTIVITY_TARGET.CHANNEL_INVITE && (
                        <CenteredBubble id={item.id} type={item.type} target={item.target} metadata={item.metadata} />
                      )}
                      {item.target === CHAT_ACTIVITY_TARGET.RANK_PROMOTION && (
                        <CenteredBubble id={item.id} type={item.type} target={item.target} metadata={item.metadata} />
                      )}
                      {item.target === CHAT_ACTIVITY_TARGET.THROTTLE && (
                        <CenteredBubble id={item.id} type={item.type} target={item.target} metadata={item.metadata} />
                      )}
                    </>
                  )}
                </Fragment>
              );
            })}
            {fetchMoreBottomPending && (
              <FetchMoreSpinnerContainer>
                <FetchMoreSpinner src={Spinner2GIF} />
              </FetchMoreSpinnerContainer>
            )}
            <InfiniteScrollBottomMarker ref={loadMoreBottomRef} />
          </ChatMessagesContainer>
        )}
      </ChatSection>
      <ChatActivitiesContainer>
        <ChatHeader>Chat Activities</ChatHeader>
        <ChatActivitiesList>
          {selectedUserInfluencer.chatActivities.map(activity => {
            return (
              <ChatActivity
                $selected={selectedChatActivity === activity}
                key={activity._id}
                onClick={() => {
                  if (fetchMoreBottomPending || fetchMoreTopPending || searchActivityInChatPending || pending) return;
                  
                  if (selectedChatActivity === activity) {
                    setSelectedChatActivity(null);
                    setPageTop(1);
                    dispatch(fetchInteractions({ userInfluencerId: selectedUserInfluencer.id, page: 1 }))
                      .unwrap()
                      .then(() => {
                        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                      });
                  } else {
                    const activityElement = document.getElementById(activity._id);
                    if (activityElement) {
                      activityElement.scrollIntoView({ block: 'center' });
                    } else {
                      const perPage = 10;
                      const totalMessages = selectedUserInfluencer.totalMessages;
                      const messageNumber = activity.messageNumber;

                      const totalPages = Math.ceil(totalMessages / perPage);
                      const selectedPage = Math.ceil((totalMessages - messageNumber) / perPage);

                      dispatch(
                        searchActivityInChat({
                          userInfluencerId: selectedUserInfluencer.id,
                          page: selectedPage,
                          totalPages,
                        }),
                      )
                        .unwrap()
                        .then(({ topPage, bottomPage }) => {
                          setShouldRerender(true);
                          setPageTop(topPage);
                          setPageBottom(bottomPage);
                          document.getElementById(activity._id).scrollIntoView({ block: 'center' });
                        });
                    }
                    setSelectedChatActivity(activity);
                  }
                }}>
                <TextContainer>
                  <Text16Bold> {activity.type}</Text16Bold>
                  <Text14Regular>{activity.target}</Text14Regular>
                </TextContainer>
                <Timestamp>{new Date(activity.timestamp).toLocaleString()}</Timestamp>
              </ChatActivity>
            );
          })}
        </ChatActivitiesList>
      </ChatActivitiesContainer>
    </MainContent>
  );
}

export default UserInfluencerDetailsPage;
