import { createAsyncThunk } from '@reduxjs/toolkit';
import influencerFactService from '../../../api/services/influencerFact';

export const fetchInfluencerFactRequests = createAsyncThunk(
  'influencerFact/fetchInfluencerFactRequests',
  async ({ page, search }) => {
    return await influencerFactService.fetchInfluencerFactRequests(page, search);
  },
);

export const fetchFactRequestsByInfluencer = createAsyncThunk(
  'influencerFact/fetchFactRequestsByInfluencer',
  async ({ page, influencerId, search }) => {
    return await influencerFactService.fetchFactRequestsByInfluencer(page, search, influencerId);
  },
);

export const fetchInfluencerFacts = createAsyncThunk(
  'influencerFact/fetchInfluencerFacts',
  async ({ page, search, influencerId }) => {
    return await influencerFactService.fetchInfluencerFacts(page, search, influencerId);
  },
);

export const createInfluencerFacts = createAsyncThunk(
  'influencerFact/createInfluencerFacts',
  async ({ facts, influencerId }) => {
    return await influencerFactService.createInfluencerFacts(influencerId, facts);
  },
);

export const acceptInfluencerFactRequest = createAsyncThunk(
  'influencerFact/acceptInfluencerFactRequest',
  async ({ influencerFactRequestIds }) => {
    return await influencerFactService.acceptInfluencerFactRequest(influencerFactRequestIds);
  },
);

export const rejectInfluencerFactRequest = createAsyncThunk(
  'influencerFact/rejectInfluencerFactRequest',
  async ({ influencerFactRequestId }) => {
    return await influencerFactService.rejectInfluencerFactRequest(influencerFactRequestId);
  },
);

export const testInfluencerFactRequest = createAsyncThunk(
  'influencerFact/testInfluencerFactRequest',
  async({influencerFactRequestId}) => {
    return await influencerFactService.testInfluencerFactRequest(influencerFactRequestId);
  }
)

export const deleteInfluencerFact = createAsyncThunk(
  'influencerFact/deleteInfluencerFact',
  async ({ influencerFactId }) => {
    return await influencerFactService.deleteInfluencerFact(influencerFactId);
  },
);

export const editInfluencerFact = createAsyncThunk(
  'influencerFact/editInfluencerFact',
  async ({ influencerFactId, text }) => {
    return await influencerFactService.editInfluencerFact(influencerFactId, text);
  },
);
