import { isAfter, isBefore } from 'date-fns';

export const isValidEmail = email => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const roundNumber = (number, decimals = 2) =>
  +(Math.round((number + Number.EPSILON) * 100) / 100).toFixed(decimals);

export const capitalizeFirstLetter = str => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const convertTagValueToLabel = tagValue => {
  if (tagValue === 'black-afro') {
    return 'Black/Afro';
  }
  return tagValue
    .split('-')
    .map(tag => titleCase(tag))
    .join(' ');
};

const titleCase = str => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const getFileExtensionFromBase64 = base64String => {
  // Regular expression to extract the MIME type
  const mimeTypeMatch = base64String.match(/^data:([A-Za-z-+/]+);base64,/);
  if (!mimeTypeMatch || mimeTypeMatch.length !== 2) {
    throw new Error('Invalid base64 string');
  }

  const mimeType = mimeTypeMatch[1];

  // Map MIME type to file extension
  const mimeToExtension = {
    'image/jpg': 'jpg',
    'image/jpeg': 'jpg',
    'image/png': 'png',
  };

  const extension = mimeToExtension[mimeType];
  if (!extension) {
    throw new Error('Unsupported MIME type');
  }

  return [extension, mimeType];
};

export const base64ToFile = (base64String, fileNamePrefix) => {
  const [extension, mimeType] = getFileExtensionFromBase64(base64String);
  // Decode the base64 string
  const byteString = atob(base64String.split(',')[1]);

  // Create an array of byte values
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  // Create a Uint8Array from the byte array
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the Uint8Array
  const blob = new Blob([byteArray], { type: mimeType }); // Adjust the MIME type as needed

  // Create a File from the Blob
  const file = new File([blob], `${fileNamePrefix}.${extension}`, { type: mimeType }); // Adjust the MIME type and file name as needed

  return file;
};

export const isCurrentDateInRange = (from, until) => {
  if (!from && !until) return false;

  const now = new Date();

  const isAfterFrom = !from || isAfter(now, new Date(from));
  const isBeforeUntil = !until || isBefore(now, new Date(until));

  return isAfterFrom && isBeforeUntil;
};
