import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from '../../../utils/constants';
import {
  acceptInfluencerFactRequestBuilder,
  createInfluencerFactsBuilder,
  deleteInfluencerFactBuilder,
  editInfluencerFactBuilder,
  fetchFactRequestsByInfluencerBuilder,
  fetchInfluencerFactRequestsBuilder,
  fetchInfluencerFactsBuilder,
  rejectInfluencerFactRequestBuilder,
  testInfluencerFactRequestBuilder,
} from './builder';

const INIT_STATE = {
  fetchInfluencerFactRequestsInfo: {
    data: [],
    maxReached: false,
    pending: false,
    fetchMorePending: false,
  },
  fetchFactRequestsByInfluencerInfo: {
    data: [],
    maxReached: false,
    pending: false,
    fetchMorePending: false,
  },
  fetchInfluencerFactsInfo: {
    data: [],
    maxReached: false,
    pending: false,
    fetchMorePending: false,
  },
  pending: {
    createInfluencerFacts: false,
    acceptInfluencerFactRequest: false,
    testInfluencerFactRequest: false,
    rejectInfluencerFactRequest: false,
    deleteInfluencerFact: false,
    editInfluencerFact: false,
  },
};

export const influencerFactSlice = createSlice({
  name: 'influencerFact',
  initialState: INIT_STATE,
  reducers: {},
  extraReducers: builder => {
    fetchInfluencerFactRequestsBuilder(builder);
    fetchFactRequestsByInfluencerBuilder(builder);
    fetchInfluencerFactsBuilder(builder);
    createInfluencerFactsBuilder(builder);
    acceptInfluencerFactRequestBuilder(builder);
    testInfluencerFactRequestBuilder(builder);
    rejectInfluencerFactRequestBuilder(builder);
    deleteInfluencerFactBuilder(builder);
    editInfluencerFactBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const selectFetchInfluencerFactRequestsInfo = state => state.influencerFactInfo.fetchInfluencerFactRequestsInfo;
export const selectFetchFactRequestsByInfluencerInfo = state =>
  state.influencerFactInfo.fetchFactRequestsByInfluencerInfo;
export const selectFetchInfluencerFactsInfo = state => state.influencerFactInfo.fetchInfluencerFactsInfo;
export const selectCreateInfluencerFactsPending = state => state.influencerFactInfo.pending.createInfluencerFacts;
export const selectAcceptInfluencerFactRequestPending = state =>
  state.influencerFactInfo.pending.acceptInfluencerFactRequest;
export const selectRejectInfluencerFactRequestPending = state =>
  state.influencerFactInfo.pending.rejectInfluencerFactRequest;
export const selectTestInfluencerFactRequestPending = state =>
  state.influencerFactInfo.pending.testInfluencerFactRequest;
export const selectDeleteInfluencerFactPending = state => state.influencerFactInfo.pending.deleteInfluencerFact;
export const selectEditInfluencerFactPending = state => state.influencerFactInfo.pending.editInfluencerFact;

export default influencerFactSlice.reducer;
