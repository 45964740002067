import styled from 'styled-components';
import { CHAT_ACTIVITY_SENDER } from '../../../../utils/constants';

export const BubbleContainer = styled.div`
  align-self: ${props => (props.$sender === CHAT_ACTIVITY_SENDER.INFLUENCER ? 'flex-start' : 'flex-end')};
  max-width: 60%;
  min-width: 5rem;
  background: ${props => (props.$sender === CHAT_ACTIVITY_SENDER.INFLUENCER ? '#f2f0f7' : '#843efe')};
  color: ${props => (props.$sender === CHAT_ACTIVITY_SENDER.INFLUENCER ? '#161320' : '#fff')};
  text-align: ${props => (props.$sender === CHAT_ACTIVITY_SENDER.INFLUENCER ? 'left' : 'right')};
  padding: 1rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  word-wrap: break-word;
  position: relative;

  &:hover .tooltip {
    display: block;
  }
    
`;

export const BubbleContent = styled.div`
  margin-bottom: 1rem; /* Adjust spacing for label and timestamp */
`;

export const Timestamp = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  font-size: 1rem;
`;

export const AudioLabel = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 1rem;
  font-size: 1rem;
  font-style: italic;

  &::after {
    content: '<audio>';
  }
`;

export const Tooltip = styled.div`
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-20%);
  padding: 0.5rem;
  border-radius: 0.5rem;
  z-index: 10;

  width: 500px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  
  text-align: left;
  background: white;
  border: 1px solid #cecece;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;
