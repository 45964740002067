import { capitalizeFirstLetter } from './util';

// export const BASE_URL = 'https://api.onlychats.com';
export const BASE_URL = 'https://staging-api.onlychats.xyz';
// export const BASE_URL = 'http://localhost:5001';

// Upload
export const UPLOAD_FILE_CONSTRAINTS = {
  TYPE: {
    PHOTO: 'PHOTO',
    VIDEO: 'VIDEO',
    AUDIO: 'AUDIO',
    REPRESENTATIVE_MEDIA: 'REPRESENTATIVE_MEDIA',
  },
  PHOTO: {
    MAX_SIZE_IN_BYTES: 10 * 1024 * 1024, // 10 MB
    ALLOWED_EXTENSIONS: ['png', 'jpeg', 'jpg', 'PNG'],
    ALLOWED_EXTENSIONS_TEXT: '.png, .jpeg, .jpg',
  },
  AUDIO: {
    MAX_SIZE_IN_BYTES: 200 * 1024 * 1024, // 200 MB
    ALLOWED_EXTENSIONS: ['mpeg3', 'x-mpeg-3', 'mpeg', 'm4a', 'x-m4a', 'wav', 'x-wav'],
    ALLOWED_EXTENSIONS_TEXT: '.mp3, .wav, .m4a',
  },
  VIDEO: {
    MAX_SIZE_IN_BYTES: 200 * 1024 * 1024, // 200 MB
    ALLOWED_EXTENSIONS: ['mp4', 'x-mp4', 'quicktime', 'mov', 'MP4', 'MOV'],
    ALLOWED_EXTENSIONS_TEXT: '.mp4, .mov, .MOV',
  },
  REPRESENTATIVE_MEDIA: {
    MAX_SIZE_IN_BYTES: 200 * 1024 * 1024, // 200 MB
    ALLOWED_EXTENSIONS: ['png', 'jpeg', 'jpg', 'mp4', 'x-mp4', 'quicktime'],
    ALLOWED_EXTENSIONS_TEXT: '.png, .jpeg, .jpg, .mp4, .mov, .MOV',
  },
};

export const ENVIRONMENT = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
};

// Store
export const LOGOUT = 'LOGOUT';

// Roles
export const INFLUENCER_ROLE = {
  INFLUENCER: 'INFLUENCER',
};
export const INFLUENCER_ROLES = [INFLUENCER_ROLE.INFLUENCER];

export const ADMIN_ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};
export const ADMIN_ROLES = [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN];

export const MODEL = {
  BASIC: 'BASIC',
  ULTRANSFW: 'ULTRANSFW',
};
export const MODELS = [MODEL.BASIC, MODEL.ULTRANSFW];
export const MODELS_FOR_FORM = MODELS.map(model => ({
  value: model,
  label: capitalizeFirstLetter(model),
}));

export const MEDIA_TYPE = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
};

export const MEDIA_CATEGORY = {
  FREE: 'FREE',
  BUNDLE: 'BUNDLE',
  PREMIUM: 'PREMIUM',
};

export const INFLUENCER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const INFLUENCER_CATEGORY = {
  PERSON: 'PERSON',
  ANIME: 'ANIME',
};
export const INFLUENCER_CATEGORIES = Object.values(INFLUENCER_CATEGORY);

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};
export const GENDERS = Object.values(GENDER);

export const VOICE_PROVIDER = {
  CARTESIA: 'CARTESIA',
  ELEVEN_LABS: 'ELEVEN_LABS',
};

export const VOICE_PROVIDER_TITLE = {
  CARTESIA: 'Cartesia',
  ELEVEN_LABS: 'Eleven Labs',
};

export const PERSONA_STYLE = {
  BRAND_FRIENDLY: 'BRAND_FRIENDLY',
  SEXUAL: 'SEXUAL',
};

export const PERSONA_STYLE_TITLE = {
  BRAND_FRIENDLY: 'Brand Friendly',
  SEXUAL: 'Sexual',
};

export const CHAT_TYPE = {
  PAID_CHAT: 'PAID_CHAT',
  FREE_CHAT: 'FREE_CHAT',
};

export const CHAT_TYPE_TITLE = {
  PAID_CHAT: 'Paid Chat',
  FREE_CHAT: 'Free Chat',
};

export const VALUE_OF_ONE_STAR_IN_USD = 0.02;
export const STARS_FOR_ONE_USD = 1 / VALUE_OF_ONE_STAR_IN_USD;

export const MASS_DM_TYPE = {
  TEXT: 'TEXT',
  FREE_PHOTO: 'FREE_PHOTO',
  PREMIUM_PHOTO: 'PREMIUM_PHOTO',
  BUNDLE: 'BUNDLE',
  FREE_VIDEO: 'FREE_VIDEO',
  VIDEO: 'VIDEO',
};

export const WITHDRAWAL_STATUS = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
};

export const CHAT_ACTIVITY_TYPE = {
  MESSAGE: 'MESSAGE',
  OFFER: 'OFFER',
  PURCHASE: 'PURCHASE',
  EVENT: 'EVENT',
};

export const CHAT_ACTIVITY_TARGET = {
  FREE_PHOTO: 'FREE_PHOTO',
  PREMIUM_PHOTO: 'PREMIUM_PHOTO',
  FREE_VIDEO: 'FREE_VIDEO',
  PREMIUM_VIDEO: 'PREMIUM_VIDEO',
  BUNDLE: 'BUNDLE',
  DICK_RATE: 'DICK_RATE',
  SEXTING: 'SEXTING',
  SUBSCRIPTION: 'SUBSCRIPTION',
  CHANNEL_INVITE: 'CHANNEL_INVITE',
  RANK_PROMOTION: 'RANK_PROMOTION',
  THROTTLE: 'THROTTLE',
};

export const CHAT_ACTIVITY_SENDER = {
  INFLUENCER: 'INFLUENCER',
  USER: 'USER',
};

export const CHAT_ACTIVITY_FORMAT = {
  TEXT: 'TEXT',
  VOICE: 'VOICE',
};

export const USER_INFLUENCERS_QUERY_RANGE_FILTER_KEYS = [
  'videoOffersCount',
  'photoOffersCount',
  'bundleOffersCount',
  'subscriptionOffersCount',
  'videoPurchaseCount',
  'photoPurchaseCount',
  'bundlePurchaseCount',
  'subscriptionPurchaseCount',
  'totalMessages',
  'totalSpent',
];

export const USER_INFLUENCERS_QUERY_LAST_N_DAYS_FILTER_KEYS = [
  'didNotPurchaseInLastNDays',
  'didNotSendMessageInLastNDays',
];
