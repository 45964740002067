import React, { Fragment, useMemo } from 'react';
import { TableBodyItem, TableItemImage } from '../../Table.styles';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../store/slices/user/slice';
import { ADMIN_ROLE } from '../../../../utils/constants';
import { isCurrentDateInRange } from '../../../../utils/util';

function InfluencerTableItem({ name, profilePhoto, username, paymentRatio, email, isDeleted, sale }) {
  const user = useSelector(selectUser);

  const saleString = useMemo(() => {
    if (!sale) return null;
    const isActive = isCurrentDateInRange(sale.from, sale.until);
    if (!isActive) return null;
    return `${sale.from ? new Date(sale.from).toLocaleDateString() : '*'} - ${
      sale.until ? new Date(sale.until).toLocaleDateString() : '*'
    }`;
  }, [sale]);

  return (
    <Fragment>
      <TableBodyItem $isDeleted={isDeleted}>
        <TableItemImage src={profilePhoto} alt="influencer-profile" />
        <p>{name || '-'}</p>
      </TableBodyItem>
      <TableBodyItem $isDeleted={isDeleted}>
        <p>{username || '-'}</p>
      </TableBodyItem>
      <TableBodyItem $isDeleted={isDeleted}>
        <p>{email || '-'}</p>
      </TableBodyItem>
      {user.role === ADMIN_ROLE.SUPER_ADMIN && (
        <TableBodyItem $isDeleted={isDeleted}>
          <p>{paymentRatio || '-'}</p>
        </TableBodyItem>
      )}
      {user.role === ADMIN_ROLE.SUPER_ADMIN && (
        <TableBodyItem $isDeleted={isDeleted}>
          <p>{saleString || '-'}</p>
        </TableBodyItem>
      )}
    </Fragment>
  );
}

export default InfluencerTableItem;
